html {
    font-size: 62.5%
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}

.Typewriter__cursor {
    color: #fff;
}